// Bootstrap JS
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';

import '../style/base.scss';

// Highlight Active Menu & active article in generic page detail
function setActive(elements: NodeListOf<HTMLAnchorElement>): void {
    let currentPath = window.location.href
    for (let i = 0; i < elements.length; i++) {
        if (currentPath.includes(elements[i].href)) {
            elements[i].classList.add('active')
        }
    }
}

let nav = document.querySelectorAll<HTMLAnchorElement>('nav ul a')
setActive(nav)

let aside = document.querySelectorAll<HTMLAnchorElement>('aside  a')
if (aside.length > 0) {
    setActive(aside)
}
